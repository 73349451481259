import Cookies from 'js-cookie'
import axios from 'axios'

const TokenKey = 'keyPearToken'


export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token) {
	return Cookies.set(TokenKey, token, {
		expires: 7
	})
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

// 秒级时间戳
export function formatDate(timestampSeconds) {
	// 将秒转换为毫秒
	const timestampMilliseconds = timestampSeconds * 1000;

	// 创建Date对象
	const date = new Date(timestampMilliseconds);

	// 获取年、月、日、时、分、秒
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	// 组合并返回格式化的日期时间字符串
	return `${month}/${day}/${year}`;
}

// 时间戳
export function formatDate2(timestampSeconds) {
	if (timestampSeconds == undefined) {
		return '-'
	}
	// 将秒转换为毫秒
	const timestampMilliseconds = timestampSeconds * 1000;

	// 创建Date对象
	const date = new Date(timestampMilliseconds);

	// 获取年、月、日、时、分、秒
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	// 组合并返回格式化的日期时间字符串
	return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}

// 毫秒级时间戳
export function formatDate3(timestampSeconds) {
	// 将秒转换为毫秒
	const timestampMilliseconds = timestampSeconds;

	// 创建Date对象
	const date = new Date(timestampMilliseconds);

	// 获取年、月、日、时、分、秒
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	// 组合并返回格式化的日期时间字符串
	return `${month}/${day}/${year}`;
}

export function myNoonTip() {
	const date = new Date();
	const hour = date.getHours();
	if (hour >= 0 && hour < 12) {
		return 'Good Morning';
	} else if (hour > 12 && hour < 18) {
		return 'Good afternoon';
	} else {
		return 'Good evening';
	}
}

export function download(row) {
	axios({ url: process.env.VUE_APP_BASE_API + `/api/v1/file?id=${row.id}`, headers: { 'X-Token': getToken() }, responseType: 'blob', method: "get" }).then(res => {
		const content = res.data
		const blob = new Blob([content])
		const fileName = row.originalFilename
		if ('download' in document.createElement('a')) { // 非IE下载
			const elink = document.createElement('a')
			elink.download = fileName
			elink.style.display = 'none'
			elink.href = URL.createObjectURL(blob)
			document.body.appendChild(elink)
			elink.click()
			URL.revokeObjectURL(elink.href) // 释放URL 对象
			document.body.removeChild(elink)
		} else { // IE10+下载
			navigator.msSaveBlob(blob, fileName)
		}
	})
}

//csv导入模板下载
export function importTemplateDownload(row) {
	axios({ url: process.env.VUE_APP_BASE_API + `/api/v1/user/importTemplateDownload`, headers: { 'X-Token': getToken() }, responseType: 'blob', method: "get" }).then(res => {
		const content = res.data
		const blob = new Blob([content])
		const fileName = "csvTemplate.csv"
		if ('download' in document.createElement('a')) { // 非IE下载
			const elink = document.createElement('a')
			elink.download = fileName
			elink.style.display = 'none'
			elink.href = URL.createObjectURL(blob)
			document.body.appendChild(elink)
			elink.click()
			URL.revokeObjectURL(elink.href) // 释放URL 对象
			document.body.removeChild(elink)
		} else { // IE10+下载
			navigator.msSaveBlob(blob, fileName)
		}
	})
}