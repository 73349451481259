<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth.js";

export default {
  destroyed() {
    removeToken()
    localStorage.clear();
  }
}
</script>

<style lang="scss"></style>
