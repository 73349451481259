import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/index.css' // global css
import '@/style/normalize.css' // global css
import locale from 'element-ui/lib/locale/lang/en'
import moment from 'moment'//导入文件

Vue.prototype.$moment = moment;//赋值使用
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('#app')
